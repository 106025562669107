import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";

const Logout = () => {
  let history = useHistory();

  useEffect(() => {
    localStorage.clear();
    history.push("/login");
  }, [])

  return(
    <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin />
    </div>
  )
}

export default Logout;
