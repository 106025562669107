const lang = {};
lang.dictionary = {
  en: {
    add: "Add",
    Edit: "Edit",
    Delete: "Delete",
    user: "User",
    picture: "Picture",
    firstName: "First Name",
    lastName: "Last Name",
    role: "Role",
    action: "Action",
    alertField: "Field Can't Empty",
    contact: "Contact",
    gender: "Gender",
    placeBirth: "Place of Birth",
    dateOfBirth: "Date of Birth",
    address: "Address",
    NoCertificate: "Certificate Number",
    institute: "Institute",
    name: "Name",
    religinon:"Religion",
    selected: "Selected",
    select: "Select",
    position: "Position",
    submit: "Submit",
    cancel: "Cancel",
    back: "Back",
    male: "Male",
    female: "Female",
    change: "Change",
    changeState: "Change State",
    alertDelete: "Are you sure you want to delete data",
    alertRejected: "Are you sure you want to Rejected this Assessment",
    alertApproved: "Are you sure you want to Approved this Assessment",
    type: "Type",
    expiredDate: "Expired Date",
    fileDuration: "Durasi File",
    map: "Maps",
    description: "Description",
    example: "Example",
    search: "Search",
    open: "Open",
    newAddress: "New Address",
    list: "List",
    competencePending: "Pending Competence",
    schedule: "Schedule",
    mainSchema: "Main Scheme",
    subSchema: "Sub Scheme",
    assign: "Assign",
    create: "Create",
    home: "Home",
    notif: "Notification",
    file: "File",
    competence: "Competence",
    activation: "Activation",
    alertexpired: "Please Select Expired Date of Certificate",
    noteListSkill:
      "This date Will be used for Deactivated competence if date more than expired",
    skill: "Skill",
    until: "Until",
    confirm: "Confirm",
    delete: "Delete",
    totalUnit: "Total Competence Unit",
    schemaName: "Scheme Name",
    schema: "Schema",
    subSchemaCode: "Sub Scheme Code",
    subSchemaName: "Sub Scheme Name",
    mainSchemaName: "Main Scheme Name",
    KBLICode: "KBLI Code",
    KBLIYear: "KBLI Year",
    KBJICode: "KBJI Code",
    KBJIYear: "KBLI Year",
    upload: "Upload",
    uploadTemplate: "Upload Template APL",
    year: "Year",
    time: "Time",
    tglSubmit: "Submit Date",
    startDate: "Start Date",
    endDate: "End Date",
    fileAcces: "File Access",
    note: "Note",
    statusGraduation: "Graduation Status",
    recomentAsesor: "Assessor Recommendation",
    positionPleno: "Pleno Position",
    plenoDate: "Pleno Date",
    ChangePlenoDate: "Change Pleno Date",
    plenoMember: "Add Pleno Members",
    assessmentLetter: "Assessment Letters",
    certificate: "Certificate Issuance Process",
    assessmentName: "Assessment Name",
    tukName: "TUK Name",
    countAsesor: "Total AssessorsmultiLanguage.note",
    countAsesi: "Total Assessee",
    countAdmin: "Total Admin",
    inputRequirements: "Input Requirements",
    requirementsType: "Requirements Type",
    assessmentDate: "Assessment Date",
    viewMore: "View More",
    reject: "Reject",
    formName: "Form Name",
    formType: "Type Name",
    DepartmentCode: "Department Code",
    DepartmentName: "Department Name",
    FacultyCode: "Faculty Code",
    alertUserName: "Please Enter Username",
    alertName: "Field Can't Empty",
    alertPatternName: "Fill With Alphabet Only",
    alertPatternContact: "Fill With Nummeric Only",
    alertEmail: "Incorrect Email Format. Exp(example@mail.com)",
    alertContact: "Insert Your Contact",
    alertMinMaxContact: "Your Contact Must Have More Than 6 Characters",
    logout: "Logout",
    alertInputNull: "No Changes",
    alertInput: "Field is Empty, Please Check Again",
    alertMaxUser: "Username Field Must be at Least 3 Characters in Length.",
    letter: "Letter",
    print: "Print Document",
    view: "View",
    alertEmptyForm: "Form Is Empty",
    alertWrongInput: "Please Check Your Input",
    alertAlready: "Data Is Already Exist",
    scheduleAsesor: "Schedule Assessors",
    ActiveAsesor: "Active Assessor",
    stateRequestAssessment: "Request Assessment",
    stateReadyPraAssessment: "Ready Pra Assessment",
    stateReview: "Review Document",
    statePraAsesment: "Pra Assessment Completed",
    stateReal: "Real Assessment",
    statePlenoFinish: "Pleno Finish",
    stateCertificate: "Certificate Ready",
    stateAsesmentReject: "Assessment Rejected",
    dateSubmit: "Submit Date",
    Assessment: "Assessment",
    Assessors: "Assessors",
    people: "People",
    APLFile: "APL File",
    HelperDownload: "There is no APL form?",
    DownloadLink: "Download",
    UserExisting: "Existing Users",
    AssignType: "Assign Type",
    withAPL: "With APL",
    notAPL: "There is no APL",
    selectAPLTab: "select the APL file tab",
    messageAlert:
      "The column required in the file is still missing, please check again",
    alreadyAssign: "Already registered in assessment",
    portfolio: "Portfolio",
    reviewDoc: "Review Document",
    PraAssessmentCompleted: "Pra Assesment Completed",
    PlenoFinish: "Pleno Finish",
    signature: "Signature",
    signatureAlready: "there is already a signature",
    no_assessors: "No Assessors",
    printCertificate: "Print Certificate",
    reset: "Reset",
    tukRequest: "TUK Request",
    viewDocument: "View Document",
    approve: "Approve",
    DataAssesment: "Data Assesment",
    PortfolioName: "Portfolio Name",
    PortfolioType: "Portfolio Type",
    yes: "yes",
    no: "no",
    waitingConfirm: "Waiting Confirmation",
    noFile: "No File Yet",
    managementLetters: "Management Letter",
    needPermission: "You need permission to access this page",
    letterName: "Letter Name",
    lastUpdate: "Last Update",
    typeFile: "Type File",
    number: "Number",
    typeTuk: "TUK Type",
    document: "Document",
    assignAsesi: "Assign Asesi",
    AssessorName: "Assessor Name",
    SuccessAsign: "Succeeded Assign",
    multipleFile: "Multiple File",
    alertRadioButton: "Pick One!",
    assessors: "Assessors",
    submission: "List of Assessment Requests",
    listRejected: "List Assessment was Rejected",
    listPortfolio: "List of Master Data Portfolio",
    listTUK: "TUK List",
    listUsers: "Users List",
    listAsesor: "Assessors List",
    listAsesi: "Asesi List",
    listMainSchema: "Main Schema List",
    listSchema: "Schema List",
    searching: "Searching",
    viewAllNotif: "View All Notification",
    asesi: "Assessee",
    listAlumni: "Alumni List",
    method_test: "Method Test",
    labelModalReject: "Write down your reasons for canceling this Assessment",
    allState: "All State",
    completed: "Completed",
    documentAsesi: "Document Assessee",
    competencyTest: "Competency Test",
    portfolioTest: "Portfolio Test",
    confirmDelete: "Are you sure to delete this data?",
    confirmReject: "Are you sure to reject this assessment?",
    UnitCompetention: "Unit Competention",
    participant: "Participant",
    nameTuk: "TUK Name",
    archives: "Archives",
    codeTuk: "TUK Code",
    codeUnit: "Unit Code",
    location: "Location",
    date: "Date",
    requestDate: "Request Date",
    continue: "Continue",
    alertNumber: "Just a Number",
    minCharacter: "Minimum 3 Character",
    alertErrorField: "There is still an incorrect column, please check again",
    purposeAssessment: "The Purpose Of The Assessment",
    generalRequiret: "General Requirement",
    attachFile: "Attach File",
    save: "Save",
    alertCancel: "Are you Sure to Cancel?",
    userAlready: "User Already in any position",
    jobs: "Jobs",
    jobPostCode:"Jobs Post Code",
    jobPhone:"Telephone Job",
    jobAddress:"Job Address",
    jobFax:"Fax Job",
    jobEmail:"Job Email",
    restore: "Restore Data",
    restoreAlert: "Are you sure you want to restore this data?",
    education: "Education",
    lastEducation: "Last Education",
    docAssesment : "Assesment Document",
    docType: "Type",
    signDate: "Sign Approved",
    approving:"Approve ?",
    approved:"Approved",
    approval:"Approval",
    date_approved:"Approved Date"
  },
  id: {
    add: "Tambah",
    Edit: "Ubah",
    Delete: "Hapus",
    user: "Pengguna",
    picture: "Gambar",
    firstName: "Nama Depan",
    lastName: "Nama Belakang",
    role: "Peran",
    action: "Aksi",
    alertField: "Field Tidak Boleh Kosong",
    contact: "No. Telp",
    gender: "Jenis Kelamin",
    placeBirth: "Tempat Lahir",
    dateOfBirth: "Tanggal Lahir",
    address: "Alamat",
    NoCertificate: "No. Sertifikat",
    institute: "Institusi",
    name: "Nama",
    religion:"Agama",
    select: "Pilih",
    selected: "Pilihan",
    position: "Jabatan",
    submit: "Simpan",
    cancel: "Batal",
    back: "Kembali",
    male: "Pria",
    female: "Wanita",
    change: "Ubah",
    changeState: "Ubah Status",
    alertDelete: "Anda Yakin Ingin Menghapus Data",
    alertRejected: "Anda Yakin Ingin Menolak Asesmen Ini",
    alertApproved: "Anda Yakin Ingin Menerima Asesmen Ini",
    type: "Tipe",
    expiredDate: "Tgl Berlaku",
    fileDuration: "Durasi Berkas",
    map: "Peta",
    description: "Deskripsi",
    example: "Contoh",
    search: "Cari",
    open: "Buka",
    newAddress: "Alamat Baru",
    list: "Daftar",
    competencePending: "Kompetensi Tertunda",
    schedule: "Jadwal",
    mainSchema: "Skema Utama",
    subSchema: "Sub Skema",
    assign: "Assign",
    create: "Buat",
    home: "Beranda",
    notif: "Pemberitahuan",
    file: "File",
    competence: "Kompetensi",
    activation: "Aktivasi",
    alertexpired: "Silakan Pilih Tanggal expired Sertifikat",
    noteListSkill:
      "Tanggal ini akan digunakan untuk kompetensi yang dinonaktifkan jika tanggal lebih dari tanggal expired",
    skill: "Keterampilan",
    until: "sampai",
    confirm: "Konfirmasi",
    delete: "Hapus",
    totalUnit: "Total Unit Kompetensi",
    schemaName: "Nama Skema",
    schema: "Skema",
    subSchemaCode: "Kode Sub Skema",
    subSchemaName: "Nama Sub Skema",
    mainSchemaName: "Nama Skema Utama",
    KBLICode: "Kode KBLI",
    KBLIYear: "Tahun KBLI",
    KBJICode: "Kode KBJI",
    KBJIYear: "Tahun KBLI",
    upload: "Unggah",
    uploadTemplate: "Unggah Template APL",
    year: "Tahun",
    time: "Waktu",
    tglSubmit: "Tgl Pengajuan",
    dateSubmisson:"Tanggal Pengajuan",
    startDate: "Tgl Mulai",
    endDate: "Tgl Berakhir",
    fileAcces: "Akses Berkas",
    note: "Catatan",
    statusGraduation: "Status Kelulusan",
    recomentAsesor: "Rekomendasi Asesor",
    positionPleno: "Jabatan saat Pleno",
    plenoDate: "Tanggal pleno",
    ChangePlenoDate: "Ganti Tanggal Pleno",
    plenoMember: "Daftarkan Anggota Pleno",
    assessmentLetter: "Surat Asesmen",
    certificate: "Proses Penerbitan Sertifikat",
    assessmentName: "Nama Kegiatan",
    tukName: "Nama TUK",
    countAsesor: "Jumlah Asesors",
    countAsesi: "Jumlah Asesi",
    countAdmin: "Jumlah Admin",
    inputRequirements: "Masukkan Persyaratan",
    requirementsType: "Tipe Persyaratan",
    assessmentDate: "Tgl Asesmen",
    viewMore: "Lihat Selengkapnya",
    reject: "Tolak",
    formName: "Nama Form",
    formType: "Tipe Form",
    DepartmentCode: "Kode Departemen",
    DepartmentName: "Nama Departemen",
    FacultyCode: "Kode Fakultas",
    alertUserName: "Masukkan Username",
    alertName: "Field Tidak Boleh Kosong",
    alertPatternName: "Anda harus menulis hanya dengan huruf",
    alertEmail: "Format Email Salah. Contoh(example@mail.com)",
    alertContact: "Masukkan Nomor Telepon Anda",
    alertPatternContact: "Anda Harus Menulis dengan Angka",
    alertMinMaxContact: "Kontak Anda Harus Lebih dari 6 Karakter",
    logout: "Keluar",
    alertInputNull: "Tidak Ada Perubahan",
    alertInput: "Field masih ada yang kosong, mohon periksa kembali.",
    alertMaxUser: "Username harus minimal 3 karakter.",
    alertEmptyForm: "Field Kosong Semua",
    letter: "Surat",
    print: "Cetak Dokumen",
    view: "Lihat",
    alertWrongInput: "Terjadi kesalahan input, mohon cek kembali inputan.",
    alertAlready: "Data Sudah Ada",
    scheduleAsesor: "Jadwal Asesor",
    ActiveAsesor: "Asesor Aktif",
    stateRequestAssessment: "Permintaan Asesmen",
    stateReadyPraAssessment: "Siap Pra Asesmen",
    stateReview: "Pengecekan Dokumen",
    statePraAsesment: "Pra asesmen Selesai",
    stateReal: "Asesmen",
    statePlenoFinish: "Pleno Selesai",
    stateCertificate: "Siap Sertifikasi",
    stateAsesmentReject: "Asesmen Ditolak",
    dateSubmit: "Tanggal Submit",
    Assessment: "Asesmen",
    Assessors: "Asesor",
    people: "Orang",
    APLFile: "Berkas APL",
    HelperDownload: "Belum ada form APL?",
    DownloadLink: "Unduh",
    UserExisting: "Users Terdaftar",
    AssignType: "Jenis Assign",
    withAPL: "Dengan APL",
    notAPL: "Belum Ada APL",
    selectAPLTab: "Pilih Tab Berkas APL",
    messageAlert: "Kolom Pada File masih Ada yang kosong,Silahkan cek kembali",
    alreadyAssign: "sudah terdaftar di asesmen",
    portfolio: "Portofolio",
    reviewDoc: "Tinjau Kecukupan Berkas",
    PraAssessmentCompleted: "Pra Asesmen Selesai",
    PlenoFinish: "Pleno Selesai",
    signature: "Tanda Tangan",
    signatureAlready: "Sudah Ada Tanda Tangan",
    no_assessors: "Tidak Ada Assessors",
    printCertificate: "Cetak Sertifikat",
    reset: "Atur Ulang",
    tukRequest: "TUK Request",
    viewDocument: "Lihat Dokumen",
    approve: "Setuju",
    DataAssesment: "Data Asesmen",
    PortfolioName: "Nama Portofolio",
    PortfolioType: "Tipe Portofolio",
    yes: "Ya",
    no: "Tidak",
    waitingConfirm: "Menunggu Konfirmasi LSP",
    noFile: "Tidak ada file",
    managementLetters: "Pengelolaan Surat",
    needPermission: "Anda membutuhkan izin untuk mengakses halaman ini.",
    letterName: "Nama Surat",
    lastUpdate: "Pembaharuan Terakhir",
    typeFile: "Tipe File",
    number: "Nomor",
    typeTuk: "Tipe TUK",
    document: "Dokumen",
    assignAsesi: "Tambah Asesi",
    AssessorName: "Assessor Name",
    SuccessAsign: "Berhasil Menugaskan",
    multipleFile: "Melampirkan Banyak File",
    alertRadioButton: "Pilih Salah Satu",
    assessors: "Asesor",
    submission: "Daftar Pemintaan Asesmen",
    listRejected: "Daftar Asesmen Dibatalkan",
    listPortfolio: "Daftar Master Data Portofolio",
    listTUK: "Daftar TUK",
    listUsers: "Daftar Pengguna",
    listAsesor: "Daftar Asesor",
    listAsesi: "Daftar Asesi",
    listMainSchema: "Daftar Skema Utama",
    listSchema: "Daftar Skema",
    searching: "Pencarian",
    viewAllNotif: "Lihat Semua Notifikasi",
    asesi: "Asesi",
    listAlumni: "Daftar Alumni",
    method_test: "Metode Uji",
    labelModalReject: "Tuliskan alasan anda mengapa membatalkan Assessment ini",
    allState: "Semua State",
    completed: "Lengkap",
    documentAsesi: "Berkas Asesi",
    competencyTest: "Uji Kompetensi",
    portfolioTest: "Uji Portofolio",
    confirmDelete: "Apakah Anda yakin akan menghapus data ini?",
    confirmReject: "Apakah Anda yakin akan menolak asesmen ini?",
    UnitCompetention: "Unit Kompetensi",
    participant: "Peserta",
    nameTuk: "Nama TUK",
    archives: "Arsip",
    codeTuk: "Kode TUK",
    codeUnit: "Kode Unit",
    location: "Lokasi",
    date: "Tanggal",
    requestDate: "Tgl Pengajuan",
    continue: "Lanjutkan",
    alertNumber: "Hanya Angka",
    minCharacter: "Minimal 3 Karakter",
    alertErrorField: "Masih Ada Kolom yang salah,Harap Cek Kembali",
    purposeAssessment: "Tujuan Asesmen",
    generalRequiret: "Persyaratan Umum",
    attachFile: "Lampirkan File",
    save: "Simpan",
    alertCancel: "Yakin Ingin Membatalkan Perubahan?",
    userAlready: "User Sudah Terdaftar",
    jobs: "Pekerjaan",
    jobPostCode:"Kode Pos Pekerjaan",
    jobPhone:"Telepon Pekerjaan",
    jobAddress:"Alamat Pekerjaan",
    jobFax:"Fax Pekerjaan",
    jobEmail:"Email Pekerjaan",
    restore: "Kembalikan Data",
    restoreAlert: "Apakah Anda yakin akan mengembalikan data ini?",
    education: "Pendidikan",
    lastEducation: "Pendidikan Terakhir",
    docAssesment : "Dokumen Asesmen",
    docType : "Tipe",
    signDate: "Tanggal Disetujui",
    approving:"Setujui ? ",
    approval: "Persetujuan",
    approved: "Disetujui",
    date_approved: "Tanggal Disetujui"
  }
};

export default lang;
