import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { Fab } from "@material-ui/core";
import { Badge } from 'antd'
import chatone from '../../assets/sound/chatone.mp3';
import { isLJKIntegration } from "../../components/config/config";
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav,
  // AppBreadcrumb,
  AppSidebarMinimizer,
} from "@coreui/react";
import ChatContainer from "./component/chatContainer";
import { Permission } from "../../_nav";
import routes, { routes2 } from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
// import '../../css/Dashboard.css';
// import "../../css/All.css";

// import "../../css/Cdn.css";

// import "../../css/Cdn.css";
// import navBarAbi from '../../css/NavbarAbi.css';
// import defaultNavbar from '../../css/Navbar.css';
// import "../../css/Button.css";
import { getRole, getLsp } from "../../components/config/config";

//  let navbarStyles = {...defaultNavbar,...navBarAbi}
const newRoutes = routes2();
class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: true,
      bahasa: "",
      permission: [],
      style: true,
      openChat: false,
      unreadChat:0,
    };
  }

  componentDidUpdate(prevProps){
    if(this.props.location !== prevProps.location){
      if(isLJKIntegration()){
        const location = this.props.location;
        let pathName = location.pathname;
        let detail = pathName.includes('/pemohon-skk/detail/');
        if(detail){
          if(location.state && location.state.pagination){
            localStorage.setItem('page-pemohon-skk', location.state.pagination);
            localStorage.setItem('detail-pemohon-skk', 'true');
          }
        }else if(pathName.includes('/pemohon-skk')){

        }else{
          localStorage.setItem('detail-pemohon-skk', 'false');
        }
      }
    }
  }

  profile(codeBahasa) {
    localStorage.setItem("bahasa", codeBahasa);
    window.location.reload();
  }

  // handleOpenChat = () => {
  //   this.setState({ openChat: true});
  // }

  // setUnreadChat = (count) => {
  //   this.setState({ unreadChat: count });
  // }

  // closeOpenChat = () => {
  //   this.setState({ openChat: false });
  // }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  render() {
    if (localStorage.length === 0) {
      return <Redirect to={"/login"} />;
    }
    // const DemoTheme = React.lazy(() => import("../Demo"));
    // const AbiTheme = React.lazy(() => import("../Abi"));

    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader
              profile={this.profile.bind(this)}
              bahasa={this.state.bahasa}
            />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          {/* <ChatContainer setUnreadChat={this.setUnreadChat} open={this.state.openChat} handleClose={this.closeOpenChat} /> */}
          <AppSidebar fixed display="lg" minimized={false}>
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav
                navConfig={
                  Permission(localStorage.getItem("bahasa"), getRole())
                  // this.state.rolePermission === 'dev' ? (
                  //   this.state.bahasa === 'en' ? (english) : (indo)
                  // ) : (
                  //   english
                  // )
                }
                {...this.props}
              />

              {getLsp() == "3" ? <AppSidebarMinimizer /> : null}
              {/* <AppSidebarMinimizer /> */}
            </Suspense>
            <AppSidebarFooter />
          </AppSidebar>
          <main className="main">
            <audio  style={{display:'none'}} id="chatone" src={chatone}></audio>
            {/* <AppBreadcrumb className="BreadCrumb" appRoutes={routes} /> */}
            <Container fluid>
              <Switch>
                {newRoutes?.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
          
            {/* <Fab onClick={this.handleOpenChat} variant="extended" color='primary' style={{position: 'fixed', right:16, bottom:16, zIndex:2}}>
              <Badge offset={[10, -13]} count={this.state.unreadChat}>
                <i className="fa fa-comments"> Group Chat</i>
              </Badge>
            </Fab> */}
          
            
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
// export default (DefaultLayout,{defaultNavbar,navBarAbi})
// export default CSSModules(DefaultLayout,navbarStyles)
