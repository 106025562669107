import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Input, Modal as ModalAntd } from "antd";

import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import "../../css/Login.css";
import "../../css/Button.css";
import {
  Brand_LSP,
  baseUrl,
  path_forgotPass,
} from "../../components/config/config";

import LogoConfirm from "../../assets/img/brand/confirm.png";
import "antd/dist/antd.css";
import moment from "moment";

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: "",
      hidden: true,
      modal: false,
      emailBody: "",
      logged_in: false,
    };
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  btn_Login = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  btn_Login = () => {
    localStorage.clear();
    window.location.replace("/login");
  };
  componentDidMount() {
    const dataLocalStorage = localStorage.getItem("email");
    if(!dataLocalStorage) {
      ModalAntd.error({
        title: "Error",
        content: "Silahkan login atau register terlebih dahulu!",
      });
      this.setState({
        logged_in:true
      })
    }
    this.setState({
      emailBody: dataLocalStorage,
    });
  }

  componentWillUnmount() {}

  toggle = () => {
    let checkIfcanResend = this.checkIfcanResend();
    if(!checkIfcanResend) {
      ModalAntd.error({
        title: "Gagal!",
        content: `Anda dapat mengirim email kembali pada pukul ` + moment(localStorage.getItem("nextResend")).format("HH:mm:ss"),
      });
      return;
    }else{
      this.setNextResend();
    }
    ModalAntd.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin mengirim ulang email?",
      onOk: () => {
        this.submitResendPassword();
      }
    });
  };

  submitResendPassword = () => {
    var data = new FormData();
    data.append("email", this.state.emailBody);
    const options = {
      method: "POST",
      url: baseUrl + path_forgotPass,
      data: data,
    };
    axios(options).then((response) => {
      ModalAntd.success({
        title: "Berhasil",
        content: "Email berhasil dikirim kembali!",
      })
      return response;
    });
  }

  checkIfcanResend = () => {
    let storageNextResend =  localStorage.getItem("nextResend");
    if(storageNextResend){
      let currentTime = moment();
      let nextResend = moment(storageNextResend);
      if(currentTime.isBefore(nextResend)){
        return false;
      }
    }
    return true;
  }

  setNextResend = () => {
    let currentTime = moment();
    localStorage.setItem("nextResend", currentTime.add(5, 'minutes').format());
  }

  toggleNested = (event) => {
    event.preventDefault();
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false,
    });

    var data = new FormData();
    data.append("email", this.state.email);
    const options = {
      method: "POST",
      url: baseUrl + path_forgotPass,
      data: data,
    };
    axios(options).then((response) => {
      return response;
    });
  };

  toggleAll = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: true,
    });
  };

  render() {
    if (localStorage.getItem("logged_in") || this.state.logged_in) {
      return <Redirect to={"/"} />;
    }

    return (
      <LoadingOverlay active={this.state.loading} spinner text="Please Wait...">
        <div className="app flex-row align-items-center animated fadeIn backgroundColor">
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>
              <b>Masukan Kembali Email Anda</b>
            </ModalHeader>
            <ModalBody>
              <Row className="center-block" style={{ width: "75%" }}>
                <Col md="20">
                  <Input
                    className="inputBox"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Please input your email account"
                    onChange={this.handleChange}
                  />
                </Col>
                <br />
              </Row>
              <Row className="center-block">
                <Col>
                  <Button className="btn-submit" onClick={this.toggleNested}>
                    Submit
                  </Button>
                </Col>
              </Row>
              <Modal
                isOpen={this.state.nestedModal}
                toggle={this.toggleNested}
                onClosed={this.state.closeAll ? this.toggle : undefined}
              >
                <ModalHeader>Confirmation Message</ModalHeader>
                <ModalBody>
                  Your email has been send, Please Check Your email
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleAll}>
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
          </Modal>
          <Card
            className="p-4"
            style={{ width: "75%", margin: "auto", maxWidth: '30rem', minHeight: '90vh', maxHeight: '1000px' }}
          >
            <CardBody>
              <Row className="logo">
                <Col>
                  <h1
                    style={{
                      textAlign: "center",
                      marginTop: "10%",
                      fontSize: '1.75rem',
                      color: "#aaa",
                    }}
                  >
                    Pendaftaran Berhasil!
                  </h1>
                </Col>
              </Row>
              <Row className="logo">
                <Col style={{ height: '50%' }}>
                  <img className="login" src={LogoConfirm} alt="" height={'100%'} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 style={{fontSize: '1.5rem'}}>Cek Email Anda!</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{fontSize: '1rem'}}>
                    Kami akan mengirimkan tautan ke email Anda untuk
                    mengubah kata sandi Anda. email Anda :
                    <p style={{ color: "#000000" }}>
                      {this.state.email == null || this.state.email == ""
                        ? this.state.emailBody
                        : this.state.email}
                    </p>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="btn-signUp"
                    // className="forgot float-right"
                    type="submit"
                    // color="link"
                    onClick={this.toggle}
                  >
                    Kirim Ulang
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col>
                  {/* <Link to={"/login"}> */}
                  <Button
                    className="btn-login"
                    type="submit"
                    onClick={this.btn_Login}
                  >
                    Login
                  </Button>
                  {/* </Link> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </LoadingOverlay>
    );
  }
}

export default Confirm;
