import axios from "axios";
import { baseUrl } from "../config/config";

export const GetToken = async () => {
  const idendity = await localStorage.getItem("identity_type");
  const secret_key = await localStorage.getItem("secret_key");
  const role = await localStorage.getItem("role");
  const userInfo = await localStorage.getItem("userdata");
  const logged = await localStorage.getItem("logged_in");
  // const dataToken = await JSON.parse(localStorage.getItem("lpjk"));
  const tokenIntegrate = "";
  return {
    idendity,
    logged,
    secret_key,
    role,
    userInfo: JSON.parse(userInfo),
    tokenIntegrate,
  };
};

export const axiosGenerate = async () => {
  const myAccessLogin = await GetToken();
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${myAccessLogin.token}`;
};

export const saveToken = async ({ token, key, client, user }) => {
  await addItemValue("token", token);
  await addItemValue("key", key);
  await addItemValue("client", client);
  await addItemValue("user_info", JSON.stringify(user));
};
export const removeToken = async () => {
  await removeItemValue("token");
  await removeItemValue("key");
  await removeItemValue("client");
  await removeItemValue("user_info");
};

export const converDateToTimetamps = (date) => {
  return Math.round(new Date(date).getTime() / 1000);
};

const removeItemValue = async (key) => {
  try {
    return true;
  } catch (exception) {
    return false;
  }
};

const addItemValue = async (key, value) => {
  try {
    return true;
  } catch (exception) {
    return false;
  }
};
