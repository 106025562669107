import React from "react";
import Loadable from "react-loadable";
import DefaultLayout from "./containers/DefaultLayout/DefaultLayout";
import "../src/css/loaderComponent.css";
import { multiLanguage } from "./components/Language/getBahasa";
import { idLSPABI, isLSPABI } from "./components/config/config.js";

function Loading() {
  return (
    <div id="preloader">
      <div id="loader" />
    </div>
  );
}

const CertificateAlumni = Loadable({
  loader: () => import("./views/CertificateAlumni/CertificateAlumni"),
  loading: Loading,
});

const ProfileUser = Loadable({
  loader: () => import("./components/Profile/Profile"),
  loading: Loading,
});

const ProfileAdminTuk = Loadable({
  loader: () => import("./components/Profile/ProfileUserAdminTuk"),
  loading: Loading,
});
const ProfileAsesor = Loadable({
  loader: () => import("./components/Profile/ProfileUserAcessor"),
  loading: Loading,
});

const ProfileAplicant = Loadable({
  loader: () => import("./components/Profile/ProfileUserAplicant"),
  loading: Loading,
});

const Assessors = Loadable({
  loader: () => import("./views/Asesor/Asesors"),
  loading: Loading,
});

const Applicant = Loadable({
  loader: () => import("./views/Applicant/Applicant"),
  loading: Loading,
});

const Data_Applicant = Loadable({
  loader: () => import("./views/DataAplicant/DataAplicant"),
  loading: Loading,
});
const Dashboard = Loadable({
  loader: () => import("./views/Dashboard/Dashboard"),
  loading: Loading,
});

const LspLogin = Loadable({
  loader: () => import("./containers/LspLogin"),
  loading: Loading,
});
const Setting = Loadable({
  loader: () => import("./views/Setting/setting"),
  loading: Loading,
});

const SubSchema = Loadable({
  loader: () => import("./views/Competences/SubSchema/SubSchema"),
  loading: Loading,
});

const EditAssessors = Loadable({
  loader: () => import("./components/EditData/EditData_acceccors"),
  loading: Loading,
});

const EditApplicant = Loadable({
  loader: () => import("./components/EditData/EditData_applicant"),
  loading: Loading,
});

const EditData = Loadable({
  loader: () => import("./components/EditData/EditData"),
  loading: Loading,
});

const EditDataUserAcessor = Loadable({
  loader: () =>
    import("./components/EditData/EditDataPengguna/EditDataUserAcessor"),
  loading: Loading,
});
const EditDataUserAplicant = Loadable({
  loader: () =>
    import("./components/EditData/EditDataPengguna/EditDataUserAplicant"),
  loading: Loading,
});
const EditDataUserAdminTuk = Loadable({
  loader: () =>
    import("./components/EditData/EditDataPengguna/EditDataUserAdminTuk"),
  loading: Loading,
});
const EditDataUserManagements = Loadable({
  loader: () =>
    import("./components/EditData/EditDataPengguna/EditDataUserManagement"),
  loading: Loading,
});

const EditSchema = Loadable({
  loader: () => import("./components/EditData/EditSchema"),
  loading: Loading,
});

const EditData_mainSchema = Loadable({
  loader: () => import("./components/EditData/EditData_mainSchema"),
  loading: Loading,
});

const EditData_UjiTertulis = Loadable({
  loader: () => import("./components/EditData/EditData_UjiTertulis"),
  loading: Loading,
});

const EditTuk = Loadable({
  loader: () => import("./components/EditData/EditData_tuk"),
  loading: Loading,
});

const MainSchema = Loadable({
  loader: () => import("./views/Competences/MainSchema/MainSchema"),
  loading: Loading,
});
// const MainSchema = Loadable({
//   loader: () => import("./components/MainSkema/index"),
//   loading: Loading,
// });

const Complaint = Loadable({
  loader: () => import("./views/Complain/Complain"),
  loading: Loading,
});

const Letters = Loadable({
  loader: () => import("./views/Letters/Letters"),
  loading: Loading,
});

const PemohonSKK = Loadable({
  loader: () => import("./views/PemohonSKK/PemohonSkk"),
  loading: Loading,
});

const DetailPemohon = Loadable({
  loader: () => import("./views/PemohonSKK/DetailPermohonan"),
  loading: Loading,
});
const EditLetters = Loadable({
  loader: () => import("./views/Letters/EditLetters"),
  loading: Loading,
});
const EditComplaint = Loadable({
  loader: () => import("./components/EditData/EditComplain"),
  loading: Loading,
});

const GroupChat = Loadable({
  loader: () => import("./views/GroupChat/GroupChat"),
  loading: Loading,
});

const ChatMessage = Loadable({
  loader: () => import("./views/GroupChat/GroupChat"),
  loading: Loading,
});
const UjiTertulis = Loadable({
  loader: () => import("./views/UjiTertulis/UjiTertulis"),
  loading: Loading,
});

const RiwayatHistory = Loadable({
  loader: () => import("./views/RiwayatHistory/RiwayatHistory"),
  loading: Loading,
});

const InputData = Loadable({
  loader: () => import("./components/InputData/InputData"),
  loading: Loading,
});

const InputData_subSchema = Loadable({
  loader: () => import("./components/InputData/InputData_subSchema"),
  loading: Loading,
});

const InputData_mainSchema = Loadable({
  loader: () => import("./components/InputData/InputData_mainSchema"),
  loading: Loading,
});

const InputData_Complaint = Loadable({
  loader: () => import("./components/InputData/InputData_Complain"),
  loading: Loading,
});

const InputLetters = Loadable({
  loader: () => import("./views/Letters/InputLetters"),
  loading: Loading,
});
const InputData_tuk = Loadable({
  loader: () => import("./components/InputData/InputData_tuk"),
  loading: Loading,
});

const ListSkill = Loadable({
  loader: () => import("./views/Asesor/Grading"),
  loading: Loading,
});

const PendingCompetance = Loadable({
  loader: () => import("./views/Asesor/PendingCompetance"),
  loading: Loading,
});

const Schedule = Loadable({
  loader: () => import("./views/Schedule/Schedule"),
  loading: Loading,
});

const JoinAsesment = Loadable({
  loader: () => import("./views/Schedule/JoinAssesment"),
  loading: Loading,
});
const JoinSkema = Loadable({
  loader: () => import("./views/Schedule/JoinSkema"),
  loading: Loading,
});

const InputData_JoinSkema = Loadable({
  loader: () => import("./components/InputData/Input_Join_Skema"),
  loading: Loading,
});
const Schedule_AdminTuk = Loadable({
  loader: () => import("./views/Schedule/Schedule_AdminTuk"),
  loading: Loading,
});
const PortofolioAsesi = Loadable({
  loader: () => import("./views/Schedule/PortofolioRoleAsesiNew"),
  loading: Loading,
});
const DokAsesmen = Loadable({
  loader: () => import("./views/Schedule/DokumenAsesmen"),
  loading: Loading,
});

const PortofolioAsesorKhusus = Loadable({
  loader: () => import("./components/Detail/PortoFolioKhususAsesor"),
  loading: Loading,
});

const DraftAssessment = Loadable({
  loader: () => import("./views/Schedule/DraftAssessment"),
  loading: Loading,
});
const Submissions = Loadable({
  loader: () => import("./views/Schedule/Submissions"),
  loading: Loading,
});

const InputAssessment = Loadable({
  loader: () => import("./components/InputData/InputData_Assessment"),
  loading: Loading,
});

const InputAssessmentAdminTuk = Loadable({
  loader: () => import("./components/InputData/InputData_AssessmentAdminTuk"),
  loading: Loading,
});

const InputAssessmentUjiTertulis = Loadable({
  loader: () => import("./components/InputData/InputData_Jawaban"),
  loading: Loading,
});

const InputAssessmentUjiEssay = Loadable({
  loader: () => import("./components/InputData/InputData_UjiEssay"),
  loading: Loading,
});

const InputUjianTertulis = Loadable({
  loader: () => import("./components/InputData/Input_Ujian_Tertulis"),
  loading: Loading,
});

const InputUjianEssay = Loadable({
  loader: () => import("./components/InputData/Input_Ujian_Tertulis_Essay"),
  loading: Loading,
});

const Schedule_accessors = Loadable({
  loader: () => import("./views/Schedule/Schedule_accessors"),
  loading: Loading,
});

const TUK = Loadable({
  loader: () => import("./views/TUK/TUK"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("./views/Users/Users"),
  loading: Loading,
});

const NotFound = Loadable({
  loader: () => import("./views/Pages/Page404/Page404"),
  loading: Loading,
});

const News = Loadable({
  loader: () => import("./views/Pages/News/NewsWebViews"),
  loading: Loading,
});

const AssignApplicant = Loadable({
  loader: () => import("./views/Schedule/AssignApplicant"),
  loading: Loading,
});

const ListApplicantTuk = Loadable({
  loader: () => import("./views/Schedule/AssignAplicanAdminTuk"),
  loading: Loading,
});
const AssignAssessors = Loadable({
  loader: () => import("./views/Schedule/AssignAssessors"),
  loading: Loading,
});

const AssignAdmin = Loadable({
  loader: () => import("./views/Schedule/AssignAdmin"),
  loading: Loading,
});

const Assign = Loadable({
  loader: () => import("./components/Detail/Detail.js"),
  loading: Loading,
});

const AssignAdminTuk = Loadable({
  loader: () => import("./components/Detail/DetailAdminTuk"),
  loading: Loading,
});
const AssignDetailArchive = Loadable({
  loader: () => import("./components/Detail/DetailArchive"),
  loading: Loading,
});

const Portofolio = Loadable({
  loader: () => import("./components/Detail/Portofolio"),
  loading: Loading,
});
const PortofolioKhusus = Loadable({
  loader: () => import("./components/Detail/PortofolioKhusus"),
  loading: Loading,
});
const PortofolioKhususArsip = Loadable({
  loader: () => import("./components/Detail/PortofolioKhususArsip"),
  loading: Loading,
});
const DetailFileAsesi = Loadable({
  loader: () => import("./components/Detail/DetailFileAsesi"),
  loading: Loading,
});
const FormDetailFileAsesi = Loadable({
  loader: () => import("./components/Modal/FormDetailFileAsesi"),
  loading: Loading,
});
const PortofolioAsesor = Loadable({
  loader: () => import("./components/Detail/Portofolio_Asessor"),
  loading: Loading,
});
const PortofolioPeserta = Loadable({
  loader: () => import("./components/Detail/PortofolioPeserta"),
  loading: Loading,
});
const HistorySoal = Loadable({
  loader: () => import("./components/Detail/DetailHistorySoal"),
  loading: Loading,
});
const Notif = Loadable({
  loader: () => import("./components/Notification/Notification"),
  loading: Loading,
});

const AssignPleno = Loadable({
  loader: () => import("./components/InputData/InputData_Plenos"),
  loading: Loading,
});

const GenerateLetters = Loadable({
  loader: () => import("./views/Correspondence/Letters"),
  loading: Loading,
});

const Alumni = Loadable({
  loader: () => import("./views/Alumni/Alumni"),
  loading: Loading,
});

const InputAlumni = Loadable({
  loader: () => import("./components/InputData/InputData_alumni"),
  loading: Loading,
});

const EditAlumni = Loadable({
  loader: () => import("./components/EditData/EditData_alumni"),
  loading: Loading,
});

const Portfolios = Loadable({
  loader: () => import("./views/MasterData/Portfolios"),
  loading: Loading,
});
const Portfolios_Umum = Loadable({
  loader: () => import("./views/MasterData/PortfoliosUmum"),
  loading: Loading,
});

const Materi_Uji = Loadable({
  loader: () => import("./views/MateriUji/Materi_Uji"),
  loading: Loading,
});

const BuktiAdministratif = Loadable({
  loader: () => import("./views/BuktiAdmin/index.js"),
  loading: Loading,
});

const InputData_Portfolios = Loadable({
  loader: () => import("./components/InputData/Input_Persyratan_Khusus"),
  loading: Loading,
});

const Input_Portfolios_umum = Loadable({
  loader: () => import("./components/InputData/Input_Portfolios_umum"),
  loading: Loading,
});

const Input_Materi_Uji = Loadable({
  loader: () => import("./components/InputData/Input_Materi_Uji"),
  loading: Loading,
});
const EditData_portfoliosMaster = Loadable({
  loader: () => import("./components/EditData/Edit_portfoliosMasterUmum"),
  loading: Loading,
});

const EditData_MateriUji = Loadable({
  loader: () => import("./components/EditData/EditData_MateriUji"),
  loading: Loading,
});
const Edit_portfoliosMasterUmum = Loadable({
  loader: () => import("./components/EditData/Edit_portfoliosMasterUmum"),
  loading: Loading,
});

const Edit_portfoliosMasterMateriUji = Loadable({
  loader: () => import("./components/EditData/Edit_portfoliosMasterUmum"),
  loading: Loading,
});
const EditActivity = Loadable({
  loader: () => import("./views/Schedule/EditActivity"),
  loading: Loading,
});

const DetailAssessment = Loadable({
  loader: () => import("./views/Schedule/DetailAsessment"),
  loading: Loading,
});
const DetailAssessmentArchive = Loadable({
  loader: () => import("./views/Schedule/DetailAsessmentArchive"),
  loading: Loading,
});

const NoSurat = Loadable({
  loader: () => import("./components/EditData/EditNoSurat"),
  loading: Loading,
});

const Rejected = Loadable({
  loader: () => import("./views/Schedule/Rejected"),
  loading: Loading,
});

const RejectedAdminTuk = Loadable({
  loader: () => import("./views/Schedule/Rejected_AdminTuk"),
  loading: Loading,
});
const ManagementSurat = Loadable({
  loader: () => import("./views/ManageSurat/ManageSurat"),
  loading: Loading,
});

const AsesiUnderAssessors = Loadable({
  loader: () => import("./views/Schedule/DetailAsesiAssessment"),
  loading: Loading,
});

// const AsesiUjiTertulis = Loadable({
//   loader: () => import("./views/MateriUji/UjiTertulisAsesi"),
//   loading: Loading,
// });
const AsesiUnderAssessment = Loadable({
  loader: () => import("./views/Schedule/AsesiReadyAssign"),
  loading: Loading,
});

const AsesiAssignAsesors = Loadable({
  loader: () => import("./views/Schedule/DetailAssesmentAsesor"),
  loading: Loading,
});

const PortfolioRoleAsesi = Loadable({
  loader: () => import("./views/Schedule/portfolioRoleAsesi"),
  loading: Loading,
});

const Archive = Loadable({
  loader: () => import("./views/Schedule/ArchiveAssessment"),
  loading: Loading,
});

const UnitCompetention = Loadable({
  loader: () => import("./views/Competences/UnitCompetention/UnitCompetention"),
  loading: Loading,
});

const PersyaratanUmum = Loadable({
  loader: () => import("./components/Detail/PersyaratanUmum"),
  loading: Loading,
});

const RestoreData = Loadable({
  loader: () => import("./views/RestoreData/RestoreData"),
  loading: Loading,
});

const PengajuanBanding = Loadable({
  loader: () => import("./views/PengajuanBanding/PengajuanBanding.js"),
  loading: Loading,
});


const HeavyVehicle = Loadable({
  loader: () => import("./views/MasterData/heavyVehicle/index.js"),
  loading: Loading,
});

const Institution = Loadable({
  loader: () => import("./views/MasterData/institution/index.js"),
  loading: Loading,
});

const AssesmentHistory = Loadable({
  loader: () => import("./views/AssesmentHistory"),
  loading: Loading,
});

const routes = [
  //menu profile
  {
    path: "/profile/user",
    name: "Pengaturan",
    component: ProfileUser,
  },
  {
    path: "/profile/admintuk",
    name: "Pengaturan",
    component: ProfileAdminTuk,
  },
  {
    path: "/profile/asesor",
    name: "Pengaturan",
    component: ProfileAsesor,
  },
  {
    path: "/profile/aplicant",
    name: "Pengaturan",
    component: ProfileAplicant,
  },

  //menu user managemen
  { path: "/users", exact: true, name: "Users", component: Users },
  {
    path: "/users/add-users",
    name: `${multiLanguage.add} Data`,
    component: InputData,
  },
  { path: "/users/edit-users/:user_id", component: EditData },
  {
    path: "/users/edit-users-accessor/:user_id",
    component: EditDataUserAcessor,
  },
  {
    path: "/users/edit-users-aplicant/:user_id",
    component: EditDataUserAplicant,
  },
  {
    path: "/users/edit-users-admintuk/:user_id",
    component: EditDataUserAdminTuk,
  },
  {
    path: "/users/edit-users-managements/:user_id",
    component: EditDataUserManagements,
  },
  {
    path: "/users/:user_id/Asesors",
    name: `${multiLanguage.Edit} Data Asesor`,
    component: EditAssessors,
  },
  //menu accessors
  { path: "/Assessors", exact: true, name: "Asesor", component: Assessors },
  { path: "/Assessors/list-skill", component: ListSkill, exact: true },
  { path: "/Assessors/list-skill/:user_id", component: ListSkill },
  {
    path: "/Assessors/pending-competance",
    name: `${multiLanguage.competencePending}`,
    component: PendingCompetance,
  },
  {
    path: "/Assessors/schedule_accessors",
    name: `${multiLanguage.schedule} Asesor`,
    component: Schedule_accessors,
  },

  //menu applicant
  { path: "/asesi", exact: true, name: "Asesi", component: Applicant },
  {
    path: "/asesi/edit-asesi/:user_id",
    name: `${multiLanguage.Edit} Asesi`,
    component: EditApplicant,
  },
  {
    path: "/data-applicant",
    exact: true,
    name: "Data Applicant",
    component: Data_Applicant,
  },

  //menu schema
  {
    path: "/schema/main-schema",
    exact: true,
    name: multiLanguage.mainSchema,
    component: MainSchema,
  },

  {
    path: "/schema/main-schema/edit-mainSchema/:schema_id",
    component: EditData_mainSchema,
    name: `${multiLanguage.Edit} ${multiLanguage.mainSchema}`,
  },
  {
    path: "/schema/main-schema/add-mainCompetence",
    name: `${multiLanguage.add} Data`,
    component: InputData_mainSchema,
  },
  {
    path: "/schema/sub-schema",
    exact: true,
    name: multiLanguage.subSchema,
    component: SubSchema,
  },

  {
    path: "/schema/schema-edit/:schema_id",
    name: `${multiLanguage.Edit} data ${multiLanguage.subSchema}`,
    component: EditSchema,
  },
  {
    path: "/schema-certification/add-schema",
    name: `${multiLanguage.add} Data`,
    component: InputData_subSchema,
  },
  {
    path: "/schema/unit-competention",
    exact: true,
    name: `${multiLanguage.UnitCompetention}`,
    component: UnitCompetention,
  },
  //Menu Uji tertulis
  {
    path: "/writen-test",
    exact: true,
    name: "Uji Tertulis",
    component: UjiTertulis,
  },
  {
    path: "/writen-test/input_data/ujian_tertulis",
    name: `${multiLanguage.add} Ujian Tertulis`,
    component: InputUjianTertulis,
  },
  {
    path: "/writen-test/input_data/ujian_essay",
    name: `${multiLanguage.add} Ujian Tertulis Essay`,
    component: InputUjianEssay,
  },
  {
    path: "/writen-test/:schema_id",
    component: EditData_UjiTertulis,
    name: "Edit Data Uji Tertulis",
  },
  {
    path: "/question/history",
    exact: true,
    name: "Riwayat History",
    component: RiwayatHistory,
  },
  {
    path: "/question/history/:schema_id",
    component: HistorySoal,
    name: `History Soal`,
  },

  //menu assessment
  {
    path: "/assessments/list",
    exact: true,
    name: "Assessments",
    component: Schedule,
  },
  {
    path: "/assessments/appeal",
    exact: true,
    name: "appeal",
    component: PengajuanBanding,
  },
  // {
  //   path: "/join-assesment",
  //   exact: true,
  //   name: "Join Assesment",
  //   component: JoinAsesment,
  // },
  {
    path: "/join-skema",
    exact: true,
    name: "Join Skema",
    component: JoinSkema,
  },
  {
    path: "/join-skema/add-join-skema",
    name: `${multiLanguage.add} Data JOin Skema`,
    component: InputData_JoinSkema,
  },
  {
    path: "/assessments/admintuk/list",
    exact: true,
    name: "Assessments",
    component: Schedule_AdminTuk,
  },
  {
    path: "/assessments/:assessment_id/portofolio-asesi",
    name: "Portofolio Asesi",
    component: PortofolioAsesi,
  },
  {
    path: "/assessments/:assessment_id/dok-asesmen",
    name: "Dokumen Asesmen",
    component: DokAsesmen,
  },
  {
    path: "/assessments/:assessment_id/:schema_id/applicants/:applicant_id/:assessment_applicant_id/:user_permission_id/portofolio-asessor-khusus",
    name: "Portofolio Asesi",
    component: PortofolioAsesorKhusus,
  },
  {
    path: "/assessments/admintuk/draft_assessment",
    exact: true,
    name: `draft ${multiLanguage.Assessment}`,
    component: DraftAssessment,
  },
  {
    path: "/assessments/input-data",
    name: `${multiLanguage.add} ${multiLanguage.schedule} Assessment`,
    component: InputAssessment,
  },
  {
    path: "/assessments/edit-data",
    name: `${multiLanguage.add} ${multiLanguage.schedule} Assessment`,
    component: InputAssessment,
  },
  {
    path: "/assessments/detail-data",
    name: `${multiLanguage.add} ${multiLanguage.schedule} Assessment`,
    component: InputAssessment,
  },
  {
    path: "/assessments/input_data/admin_tuk",
    name: `${multiLanguage.add} ${multiLanguage.schedule} Assessment AminTuk`,
    component: InputAssessmentAdminTuk,
  },
  {
    path: "/assessments/input_data/uji_tertulis",
    name: `${multiLanguage.add} ${multiLanguage.schedule} Assessment Pilihan Ganda`,
    component: InputAssessmentUjiTertulis,
  },
  {
    path: "/assessments/input_data/uji_essay",
    name: `${multiLanguage.add} ${multiLanguage.schedule} Assessment Essay`,
    component: InputAssessmentUjiEssay,
  },
  {
    path: "/assessments/:assessment_id/applicants/:assessment_applicant_id/portofolio",
    name: "Portofolio Asesi",
    component: Portofolio,
  },
  {
    path: "/assessments/:schema_id/applicants/:applicant_id/:assessment_applicant_id/:user_permission_id/portofolio-khusus",
    name: "Portofolio Asesi",
    component: PortofolioKhusus,
  },
  {
    path: "/assessments/applicants/arsip/portofolio-khusus",
    name: "Portofolio Asesi",
    component: PortofolioKhususArsip,
  },
  {
    path: "/assessments/:assessment_id/applicants/:assessment_applicant_id/detail-file-asesi/form",
    name: "Form Detail File Asesi",
    component: FormDetailFileAsesi,
  },
  {
    path: "/assessments/:assessment_id/applicants/:assessment_applicant_id/detail-file-asesi",
    name: "Detail File Asesi",
    component: DetailFileAsesi,
  },

  {
    path: "/assessments/:schedule_assessment_id/edit-activity",
    name: `${multiLanguage.Edit} ${multiLanguage.Assessment}`,
    component: EditActivity,
  },
  {
    path: "/assessments/:assessment_id/applicants/:assessment_applicant_id/portofolio-assesor",
    name: "Portofolio Asesi",
    component: PortofolioAsesor,
  },
  {
    path: "/applicants/:applicant_id/:schema_id/:user_permission_id/persyaratan-peserta",
    name: "Portofolio Asesi",
    component: PortofolioPeserta,
  },
  {
    path: "/assessments/:assessment_id/assign",
    name: "Assign Assessment",
    exact: true,
    component: Assign,
  },
  {
    path: "/assessments/:assessment_id/assignAdminTuk",
    name: "Assign Assessment AdminTuk",
    exact: true,

    component: AssignAdminTuk,
  },
  {
    path: "/assessments/:assessment_id/assign_archive",
    name: "Assign Assessment",
    exact: true,
    component: AssignDetailArchive,
  },
  {
    path: "/assessments/:assessment_id/assign-admin/:run",
    component: AssignAdmin,
  },
  {
    path: "/assessments/:assessment_id/assign-applicant/:schema_id/:run",
    component: AssignApplicant,
  },
  {
    path: "/assessments/:assessment_id/list-applicant/:schema_id/:run",
    component: ListApplicantTuk,
  },
  {
    path: "/assessments/:assessment_id/generate",
    component: GenerateLetters,
  },
  {
    path: "/assessments/:assessment_id/assign-assessors",
    component: AssignAssessors,
  },
  {
    path: "/assessments/:assessment_id/detail",
    name: "Detail Assessment",
    component: DetailAssessmentArchive,
  },
  {
    path: "/assessments/:assessment_id/assign/:assessor_id/:is_user_pleno",
    name: "Detail Asesi on Assessments",
    component: AsesiAssignAsesors,
    exact: true,
  },
  {
    path: "/assessments/:assessment_id/assign/:assessor_id",
    name: "Detail Asesi on Assessments",
    component: AsesiUnderAssessors,
    exact: true,
  },
  {
    path: "/assessments/:assessment_id/penyelia/:assessor_id",
    name: "Detail Asesi on Assessments",
    component: AsesiUnderAssessors,
    exact: true,
  },
  // {
  //   path: "/assessments/:assessment_id/:assessor_id",
  //   name: "Detail Asesi on Assessments",
  //   component: AsesiUjiTertulis,
  // },
  {
    path: "/assessments/:assessment_id/detail-asesi",
    name: "Detail Asesi on Assessments",
    component: AsesiUnderAssessment,
  },
  {
    path: "/assessments/:assessment_id/letters/:assessment_letter_id",
    name: `${multiLanguage.Edit} No.Surat`,
    component: NoSurat,
  },
  {
    path: "/assessments/:assessment_id/plenos/:run",
    name: `${multiLanguage.create} List Pleno`,
    component: AssignPleno,
  },
  {
    path: "/assessments/submission",
    exact: true,
    name: "Submissions",
    component: Submissions,
  },
  {
    path: "/assessments/rejected",
    exact: true,
    name: `${multiLanguage.list} ${multiLanguage.Assessment} ${multiLanguage.reject}`,
    component: Rejected,
  },
  {
    path: "/assessments/admintuk/rejected",
    exact: true,
    name: `${multiLanguage.list} ${multiLanguage.Assessment} ${multiLanguage.reject}`,
    component: RejectedAdminTuk,
  },
  {
    path: "/assessments/:assessment_id/portofolio",
    name: "Portfolio Assessment",
    component: PortfolioRoleAsesi,
  },
  {
    path: "/assessments/archives",
    exact: true,
    name: `${multiLanguage.archives} ${multiLanguage.schedule}`,
    component: Archive,
  },
  {
    path: "/applicants/:applicant_id/persyaratan-umum",
    name: "Persyaratan Umum Asesi",
    component: PersyaratanUmum,
  },

  //menu TUK
  { path: "/tuk", exact: true, name: "TUK", component: TUK },
  {
    path: "/tuk/add-tuk",
    name: `${multiLanguage.add} Data TUK`,
    component: InputData_tuk,
  },
  {
    path: "/tuk/edit-tuk/:tuk_id",
    name: `${multiLanguage.Edit}`,
    component: EditTuk,
  },

  //alumni
  {
    path: "/alumnis",
    exact: true,
    name: "Alumni",
    component: Alumni,
  },
  {
    path: "/alumni/certificate/:alumni_id",
    exact: true,
    name: "Certificate Alumni",
    component: CertificateAlumni,
  },
  {
    path: "/alumni/certificate/:alumni_id/:is_new",
    exact: true,
    name: "Certificate Alumni",
    component: CertificateAlumni,
  },
  {
    path: "/alumnis/add-alumni",
    component: InputAlumni,
    name: "Tambah Data Alumni",
  },
  {
    path: "/alumnis/edit-alumni/:alumni_id",
    component: EditAlumni,
    name: "Edit Data Alumni",
  },

  //management surat
  {
    path: "/management-letters",
    component: ManagementSurat,
    name: multiLanguage.mainSchema,
    exact: true,
  },

  // etc
  {
    path: "/",
    exact: true,
    name: multiLanguage.home,
    component: DefaultLayout,
  },
  { path: "/dashboard", component: Dashboard },
  // { path: "/lsplogin", component: LspLogin },
  { path: "/setting", component: Setting },
  { path: "/404", component: NotFound },
  { path: "/articles/:article_id", component: News },
  {
    path: "/message",
    name: multiLanguage.notif,
    component: Notif,
  },

  //master data
  {
    path: "/portfolios",
    exact: true,
    name: "Master Data",
    component: Portfolios,
  },
  {
    path: "/portfolios-umum",
    exact: true,
    name: "Master Data",
    component: Portfolios_Umum,
  },
  {
    path: "/materi-uji",
    exact: true,
    name: "Master Data",
    component: Materi_Uji,
  },
  {
    path: "/bukti-administratif",
    exact: true,
    name: "Data Bukti Administratif",
    component: BuktiAdministratif,
  },
  {
    path: "/portfolios/input",
    name: `${multiLanguage.add} data Portofolio`,
    component: InputData_Portfolios,
  },
  {
    path: "/portfolios-umum/input",
    name: `${multiLanguage.add} data Portofolio`,
    component: Input_Portfolios_umum,
  },
  {
    path: "/materi-uji/input",
    name: `${multiLanguage.add} data Portofolio`,
    component: Input_Materi_Uji,
  },
  {
    path: "/materi-uji/edit-materi-uji/",
    name: `${multiLanguage.Edit} Materi Uji`,
    component: EditData_MateriUji,
  },
  {
    path: "/portfolios-umum/edit-portfolios/:requirement_master_id",
    name: `${multiLanguage.Edit} Master Portofolios`,
    component: Edit_portfoliosMasterUmum,
  },
  {
    path: "/portfolios/edit-portfolios/:requirement_master_id",
    name: `${multiLanguage.Edit} Master Portofolios`,
    component: EditData_portfoliosMaster,
  },
  {
    path: "/materi-uji/edit-portfolios/:requirement_master_id",
    name: `${multiLanguage.Edit} Master Portofolios`,
    component: Edit_portfoliosMasterMateriUji,
  },
  //Chat
  // {
  //   path: "/chat",
  //   name: "Group Chat",
  //   component: GroupChat,
  //   badge: "test",
  // },
  // {
  //   path: "/chatting",
  //   name: "Chat",
  //   component:Chat,
  //   badge: "test",
  // },
  // {
  //   path: "/chating/:id",
  //   name: "Group Chat",
  //   component: ChatMessage,
  //   badge: "test",
  // },
  //Restore Data
  {
    path: "/restore-data",
    exact: true,
    name: "Restore Data",
    component: RestoreData,
  },
  // Complain
  {
    path: "/complaint",
    exact: true,
    name: "Keluhan Alikasi",
    component: Complaint,
  },
  // Surat
  {
    path: "/letters",
    exact: true,
    name: "Pengelolaan Surat",
    component: Letters,
  },
  {
    path: "/letters/edit-letters/:letter_id",
    exact: true,
    name: "Edit Surat",
    component: EditLetters,
  },

  {
    path: "/complain/edit-complain/:row_id",
    name: `${multiLanguage.Edit}`,
    component: EditComplaint,
  },
  // Pemohon SKK
  // {
  //   path: "/pemohon-skk/detail/:id_izin",
  //   name: `${"Detail"}`,
  //   component: DetailPemohon,
  // },
  // {
  //   path: "/pemohon-skk",
  //   name: "Pemohon SKK",
  //   component: PemohonSKK,
  // },

  {
    path: "/complain/add-complain",
    name: `${multiLanguage.add} Data`,
    component: InputData_Complaint,
  },
  {
    path: "/letters/add-letters",
    name: `${multiLanguage.add} Data`,
    component: InputLetters,
  },
  {
    path: "/asesmen-history",
    name: "Riwayat Asesmen",
    exact: true,
    component: Alumni,
  },
  {
    path: "/Assessors/asesmen-history",
    name: "Riwayat Asesmen",
    exact: true,
    component: AssesmentHistory,
  },
];

export const routes2 = () => {
  const isLPJK = localStorage.getItem('isLPJKIntegration');
  const role = localStorage.getItem('role');
  const ref_id = JSON.parse(localStorage.getItem('ref_id'));
  let newRoute = routes;
  if (role ==="SUP" || role === "ADM"){
    if (isLSPABI()){
      newRoute = newRoute.concat([
        {
          path: "/intitutions",
          exact: true,
          name: "Institution",
          component: Institution,
        },
        {
          path: "/heavy-vehicles",
          exact: true,
          name: "Alat Berat",
          component: HeavyVehicle,
        }
      ]);
    } else {
      newRoute = newRoute.concat([
        {
          path: "/intitutions",
          exact: true,
          name: "Institution",
          component: Institution,
        }
      ]);
    }
  }

  let routePemohonSkk = [
    {
      path: "/pemohon-skk/detail/:id_izin",
      name: `${"Detail"}`,
      exact: true,
      component: DetailPemohon,
    },
    {
      path: "/pemohon-skk/detail/:id_izin/:second_param",
      name: `${"Detail"}`,
      component: DetailPemohon,
    },
    {
      path: "/pemohon-skk",
      name: "Pemohon SKK",
      component: PemohonSKK,
    },
  ];
  if(isLPJK && isLPJK!=='false'){
    newRoute = newRoute.concat(routePemohonSkk);
  }
  return newRoute;
}

export default routes;
