import React from 'react';
import { Button as Button } from "reactstrap";
import { Modal, Button as BtnAntd, Alert, Spin } from 'antd';

const ModalConfirm = (props) => {
  const { loading, open, toggle, submit, message } = props
  return(
    <Modal
      visible={open}
      title=""
      onCancel={toggle}
      closable={false}
      footer={null}
    >
      <Alert
          type="warning"
          showIcon
          message={`Anda akan membuat akun ${message}, Lanjutkan?`}
      />
      <div style={{ width: "100%", display:'flex', justifyContent:'center', marginTop: '20px' }}>
          <Button disabled={loading} color="secondary" style={{ borderColor: 'grey', marginRight: '10px' }} onClick={() => toggle()}>
            Batal
          </Button>{" "}
          <Spin spinning={loading}>
            <Button
              size="default"
              color="primary"
              key="wtfw"
              onClick={() => submit()}
              disabled={loading}
            >
              Lanjutkan
            </Button>
          </Spin>
      </div>
    </Modal>
  )
}

export default ModalConfirm;