import React, { useState, useEffect} from "react";
import { Drawer, List } from "antd";
import moment from "moment";

const DrawerInfoRoom = (props) => {
  const [user_id, setUser_id] = useState(null);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userdata"));
    if(userData){
      setUser_id(userData.user_id);
    }
  }, [])
  const { open, onClose, data, roomName, dateRoom } = props

  const renderRole = (role_code)  => {
    let role = 'Asesi';
    switch (role_code) {
      case 'ACS':
        role = 'Asesor';
        break;
      case 'ADM':
        role = 'Admin';
        break;
      case 'SUP':
        role = 'Admin';
        break;
      default:
        break;
    }
    return role;
  }

  return(
    <div>
      <Drawer className="drawer-info" visible={open} onClose={onClose} style={{ zIndex:1502 }} title="Info Group" bodyStyle={{ height:'100%', padding:0 }}>
        <div style={{ height:'100%', width:'100%', display:'flex', flexDirection:'column' }}>
          <div style={{ width:'100%', padding:'1em'}}>
            <div>
              <h5>{roomName}</h5>
            </div>
            <div>
              <div style={{marginBottom:0}}>Tanggal Dibuat</div>
              <div style={{ fontWeight:'bold'}}>{dateRoom ? moment(dateRoom).format('DD/MM/YYYY') : ''}</div>
            </div>
          </div>
          <div style={{ height:'100%', overflow:'hidden' }}>
            <div style={{marginBottom:0, marginTop:'10px', paddingLeft:'.6em', fontWeight:500, fontSize:'1.5em' }}>Peserta</div>
            <div id="scroll-preserta" style={{ height:'100%', maxHeight:'80%', overflowY:'scroll', padding:'0 1em', borderRadius:'10px'}}>
              <List dataSource={data} style={{ }} renderItem={item => {
                return (
                  <List.Item>
                    <div style={{ display:'flex', flexDirection:'column' }}>
                      <h5 style={{ marginBottom:0 }}>{item.user ? item.user_id===parseInt(user_id ? user_id : "") ? 'Anda' : item.user.first_name+' '+item.user.last_name : '-'}</h5>
                      <small>{renderRole(item.user.role_code)}</small>
                    </div>
                  </List.Item>
                )
              }}>
              </List>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default DrawerInfoRoom;