import React, { Component, Suspense } from "react";
import { PostData } from "../services/PostData";
import { insertData, GetListData } from "../components/config/actions";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
  Container,
  Row,
  Alert,
} from "reactstrap";
import { multiLanguage } from "../components/Language/getBahasa";
import { Input } from "antd";
import { withAlert } from "react-alert";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

// import "../css/All.css";
// import "../css/LSP_Demo.css"; 

// import "../css/Button.css";
import {
  baseUrl,
  path_forgotPass,
  path_refreshToken,
  Brand_LSP,
} from "../components/config/config";
import LogoImage from "../assets/img/brand/nas_logo.png";
import { Digest } from "./Helpers/digest";
import "antd/dist/antd.css";

class LspLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logged_in: false,
      message: "",
      hidden: true,
      payload: [],
      response: "",
      user_permission_id: "",
      color:"",
      hideCopyright: false
    };
  }

  getListLsp = () => {
    this.setState({
      loading: true,
    });
    GetListData("/lsp")
      .then((res) => {
        
        if (res.responseStatus == "SUCCESS") {
          this.setState({
            payload: res.data,
            loading: false,
          });
        }
      })
      .catch((e) => {
       
        this.setState({ loading: false });
        if (e.status_code === 400) {
        }
        if (e.status_code === 401) {
          localStorage.clear();
          window.location.replace("/login");
        }
      });
  };

  cekDomain = () => {
    let domain = window.location.hostname;
    if(domain === "app.vokasea.id"){
      this.setState({ hideCopyright:true })
    } else if (domain === "areaindonesia.vokasea.id") {
      this.setState({ hideCopyright:true })
    } else if (domain === "oshe.vokasea.id"){
      this.setState({ hideCopyright:true })
    } else if (domain === "nki.vokasea.id"){
      this.setState({ hideCopyright:true })
    }
  }

  componentDidMount() {
    this.cekDomain();
    this.getListLsp();
  }
  lspLoginSubmit = (item) => {

    this.setState({
      color:'red'
    })
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    if (this.state.user_permission_id == "") {
      this.setState({
        hidden: false,
        message: "Pilih salah satu LSP",
      });
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {};
  render() {
    const { payload } = this.state;
    return (
      <LoadingOverlay active={this.state.loading} spinner text="Please Wait...">
        <div className="app flex-row align-items-center animated fadeIn backgroundColor">
          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <Card
                  className="p-4"
                  style={{ width: "75%", margin: "auto", height: "665px" }}
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <h1
                          style={{
                            marginLeft: 40,
                            marginRight: 40,
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          You're in Developer Mode
                        </h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6
                          style={{
                            marginLeft: 40,
                            marginRight: 40,
                            textAlign: "center",
                            color: "#636464",
                          }}
                        >
                          Please choose the LSP to get access into web admin
                        </h6>
                      </Col>
                    </Row>
                    <div style={{ overflowX: "auto" }}>
                      <Row>
                        {payload.map((item) => {
                          
                          return (
                            <Col sm="3">
                              <Card style={{ width: 100, height: 150 ,backgroundColor:this.state.color}}>

                                <a onClick={() => this.lspLoginSubmit(item)}>
                                  <CardImg
                                    top
                                    style={{
                                      width: 40,
                                      height: 60,
                                      marginLeft: 30,
                                    }}
                                    src={LogoImage}
                                    alt=""
                                  />

                                  <CardText style={{ textAlign: "center" }}>
                                    <small
                                      className="text-muted"
                                      style={{ fontSize: "10px" }}
                                    >
                                      {item.lsp_name}
                                    </small>
                                  </CardText>
                                </a>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                    {/* <Row>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />

                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP Pramindo</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />
                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP PIA</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />
                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP PIM</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />
                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP Energi</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />
                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP CKPNI</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />
                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP GPPB</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />
                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP Staging</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <a>
                            <CardImg
                              top
                              style={{ width: 70, height: 90, marginLeft: 10 }}
                              src={LogoImage}
                              alt=""
                            />
                            <CardText style={{ textAlign: "center" }}>
                              <small className="text-muted">LSP Demo</small>
                            </CardText>
                          </a>
                        </Card>
                      </Col>
                    </Row> */}
                    <Row className="center-block">
                      <Alert
                        color="danger"
                        hidden={this.state.hidden}
                        style={{
                          marginTop: "3%",
                          fontSize: "72%",
                          marginBottom: "3%",
                        }}
                      >
                        {this.state.message}
                      </Alert>
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <Col>
                        <Button
                          className="btn-login"
                          type="submit"
                          onClick={this.validate}
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                    {!this.state.hideCopyright && (
                      <Row
                        className="footerCopyright"
                        style={{ marginTop: "40px" }}
                      >
                        <Col>
                          Copyright &copy; 2019,
                          <a href="https://www.aplikasisertifikasi.com">NAS</a>.
                          All rights reserved
                        </Col>
                      </Row>
                    )}
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <NotificationContainer />
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withAlert(LspLogin);
